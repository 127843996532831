<template>
  <div class="downloads">
    <van-sticky>
      <van-nav-bar title="Meu download" left-arrow @click-left="onClickLeft" class="retreat">
        <template #right>
          Editar
        </template>
      </van-nav-bar>
    </van-sticky>
    <van-tabs>
      <van-tab v-for="item in downloads" :title="item.name" :key="item.id" :name="item.id">
        <van-empty class="custom-image" :image="require(`../../assets/空页面.png`)" description="Sem conteúdo ainda" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  name: '',
  components: {},
  data() {
    return {
      downloads: [
        { name: 'baixiando', id: 1 },
        { name: 'baixiando', id: 2 },
      ]
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  mounted() { },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/.van-nav-bar {
  background-color: var(--main-color);
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--title-color);
}

/deep/.van-nav-bar .van-icon {
  opacity: 0.8;
  font-size: 18px;
  color: var(--title-color);
}

/deep/.van-nav-bar__title {
  opacity: 0.8;
  font-size: 18px;
  color: var(--title-color);
}
/deep/.van-tabs__nav{
  background-color: var(--main-color);
}
/deep/.van-tabs__line{
  width: 5vw;
  background-color: var(--title-color);
  bottom: 25px;
}
/deep/.van-tab{
  color: var(--title-color);
  opacity: 0.8;
}
/deep/.van-tab--active{
  color: var(--title-color);
  opacity: 1;
}
</style>
